/* global basketMode, identifier */

import React from 'react';
import ReactDOM from "react-dom";
import CookieConsent from "./lib/CookieConsent.jsx";

const CookieConsentInstance = ReactDOM.render(
  <CookieConsent
    basketMode={(typeof basketMode !== 'undefined') ? basketMode : undefined}
    identifier={(typeof identifier !== 'undefined') ? identifier : undefined}
  />,
  document.getElementById('cookie-consent-container')
);

window.CookieConsent = CookieConsentInstance
