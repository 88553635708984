/**
 * Posts a message to the parent window.
 *
 * @param {object} info what to pass to the parent window as the data property of the event
 */
function notifyEmbedder(info) {
  if (window.parent) {
    window.parent.postMessage(info, '*');
  }
}

export {notifyEmbedder}
