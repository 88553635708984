import React from 'react';

import PropTypes from 'prop-types';

class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.state = {
      disabled: this.props.disabled !== undefined ? this.props.disabled : false
    }
  }

  handleToggle() {
    if (this.props.handleToggle !== undefined && !this.props.disabled) {
      if (this.props.toggleTarget) {
        this.props.handleToggle(this.props.toggleTarget)
      } else {
        this.props.handleToggle()
      }
    }
  }

  render() {
    return (
      <div
        onClick={this.handleToggle}
        className='toggle-switch-container'>
        <input
          className='toggle-switch__input'
          type="checkbox"
          checked={this.props.currentState}
          disabled={this.state.disabled}
          readOnly={true}/>
        <span className='toggle-switch__span'/>
        <label>{this.props.toggleLabel}</label>
      </div>
    )
  }
}

ToggleSwitch.propTypes = {
  currentState: PropTypes.bool.isRequired,
  toggleLabel: PropTypes.string.isRequired,
  toggleTarget: PropTypes.string,
  handleToggle: PropTypes.func,
  disabled: PropTypes.bool,
}

export default ToggleSwitch
