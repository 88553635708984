window.dataLayer = window.dataLayer || [];

function gTag() {
  window.dataLayer.push(arguments);
}

function initCookieConsentInDataLayer() {
  gTag('consent', 'default', {
    // Analytic, granted by default but custom possible
    'analytics_storage': 'granted',
    // Marketing
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'personalization_storage': 'denied',
    // Functional, always allowed
    'functionality_storage': 'granted',
    'security_storage': 'granted',
  });

  fireCookieConsentInitEvent()
}

function fireCookieConsentInitEvent() {
  window.dataLayer.push({
    event: "cookie_consent_default",
  })
}

function pushCookieConsentToDataLayer(analyticsConsent, marketingConsent) {
  gTag('consent', 'update', {
    // Analytic
    'analytics_storage': analyticsConsent ? 'granted' : 'denied',
    // Marketing
    'ad_storage': marketingConsent ? 'granted' : 'denied',
    'ad_user_data': marketingConsent ? 'granted' : 'denied',
    'ad_personalization': marketingConsent ? 'granted' : 'denied',
    'personalization_storage': marketingConsent ? 'granted' : 'denied',
    // Functional, always allowed
    'functionality_storage': 'granted',
    'security_storage': 'granted',
  });

  fireCookieConsentUpdateEvent()
}

function fireCookieConsentUpdateEvent() {
  window.dataLayer.push({
    event: "cookie_consent_update"
  })
}

export {initCookieConsentInDataLayer, pushCookieConsentToDataLayer}
