/* global i18n */
import React from "react";

import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import {Collapse} from 'react-collapse';
import ToggleSwitch from './ToggleSwitch.jsx';

import {
  initCookieConsentInDataLayer,
  pushCookieConsentToDataLayer,
} from './gtmconsent';

import {notifyEmbedder} from './notifyEmbedder'

const CONSENT_EXPIRES_ON = 'consentExpiresOn';
const CONSENT_ANALYTICAL = 'consentAnalyticalCookies';
const CONSENT_MARKETING = 'consentMarketingCookies';

class CookieConsent extends React.Component {
  constructor(props) {
    super(props);

    this.consentForm = React.createRef()

    this.initHeight = this.initHeight.bind(this)
    this.updateHeight = this.updateHeight.bind(this)
    this.getConsentExpirationDate = this.getConsentExpirationDate.bind(this)
    this.saveCurrentConsent = this.saveCurrentConsent.bind(this)
    this.toggleCookies = this.toggleCookies.bind(this)
    this.toggleMoreInfo = this.toggleMoreInfo.bind(this)
    this.toggleCookie = this.toggleCookie.bind(this)
    this.acceptAll = this.acceptAll.bind(this)

    this.collapsableContainer = this.collapsableContainer.bind(this)
    this.readMoreInfo = this.readMoreInfo.bind(this)
    this.moreInfo = this.moreInfo.bind(this)

    this.state = {
      isCustomEmbeddedShop:
        this.props.basketMode !== undefined
        && this.props.identifier !== undefined,

      consentMissingOrExpired: false,
      consentAnalyticalCookies: true,
      consentMarketingCookies: false,
      cookiesExpanded: false,
      moreInfoExpanded: false,
      initialFrameHeight: 176,
    }
  }

  componentDidMount() {
    if (this.state.isCustomEmbeddedShop && window.top === window.self) {
      // Don't render second consent form when in
      // minimal embedded ticketshop
      return
    }

    initCookieConsentInDataLayer()

    let consentExpiresOnString = localStorage.getItem(CONSENT_EXPIRES_ON)
    if (consentExpiresOnString === null) {
      this.setState({
        consentMissingOrExpired: true
      }, this.initHeight)
      return
    }

    let consentExpiresOnDate = moment(consentExpiresOnString)
    let currentDate = moment()

    let consentAnalyticalCookies = localStorage.getItem(CONSENT_ANALYTICAL) === "true"
    let consentMarketingCookies = localStorage.getItem(CONSENT_MARKETING) === "true"

    this.setState({
      consentAnalyticalCookies: consentAnalyticalCookies,
      consentMarketingCookies: consentMarketingCookies
    })

    if (consentExpiresOnDate <= currentDate) {
      this.setState({
        consentMissingOrExpired: true,
      }, this.initHeight)
    } else {
      // Use variables instead of state as state
      // sometimes does not update immediately
      pushCookieConsentToDataLayer(
        consentAnalyticalCookies,
        consentMarketingCookies
      )
    }
  }

  initHeight() {
    if (this.state.isCustomEmbeddedShop) {
      // Timeout is necessary to make sure this height is posted to the iFrame container
      // AFTER the MinimalTicketSelector initial height (which is also posted with a delay)
      // making sure that we overwrite it to nicely fit this CookieConsent form.
      setTimeout(() => {
        notifyEmbedder({
          'height': (this.consentForm.current.clientHeight + 20),
          'identifier': this.props.basketMode ? 'basket' : this.props.identifier
        })
      }, 50)
    }
  }

  updateHeight() {
    if (this.state.isCustomEmbeddedShop && this.consentForm.current !== null) {
      notifyEmbedder({
        'height': (this.consentForm.current.clientHeight + 20),
        'identifier': this.props.basketMode ? 'basket' : this.props.identifier
      })
    }
  }

  resetHeight() {
    if (this.state.isCustomEmbeddedShop) {
      notifyEmbedder({
        'height': this.state.initialFrameHeight,
        'identifier': this.props.basketMode ? 'basket' : this.props.identifier
      })
    }
  }

  getConsentExpirationDate() {
    return moment().add(1, 'year').toISOString();
  }

  saveCurrentConsent() {
    pushCookieConsentToDataLayer(
      this.state.consentAnalyticalCookies,
      this.state.consentMarketingCookies
    )

    localStorage.setItem(CONSENT_EXPIRES_ON, this.getConsentExpirationDate().toString())
    localStorage.setItem(CONSENT_ANALYTICAL, this.state.consentAnalyticalCookies.toString())
    localStorage.setItem(CONSENT_MARKETING, this.state.consentMarketingCookies.toString())

    this.setState({consentMissingOrExpired: false}, this.resetHeight)
  }

  toggleCookies() {
    this.setState({
      cookiesExpanded: !this.state.cookiesExpanded,
      moreInfoExpanded: false
    }, this.updateHeight)
  }

  toggleMoreInfo() {
    this.setState({
      moreInfoExpanded: !this.state.moreInfoExpanded,
      cookiesExpanded: false
    }, this.updateHeight)
  }

  toggleCookie(preference) {
    switch (preference) {
      case CONSENT_ANALYTICAL:
        this.setState({
          consentAnalyticalCookies: !this.state.consentAnalyticalCookies
        })
        break
      case CONSENT_MARKETING:
        this.setState({
          consentMarketingCookies: !this.state.consentMarketingCookies
        })
        break
      default:
        break
    }
  }

  acceptAll() {
    this.setState(() => ({
      consentAnalyticalCookies: true,
      consentMarketingCookies: true
    }), this.saveCurrentConsent)
  }

  collapsableContainer(collapseState, content) {
    if (!this.state.isCustomEmbeddedShop) {
      return (
        <Collapse
          isOpened={collapseState}
          hasNestedCollapse>
          {content}
        </Collapse>
      )
    } else {
      return (collapseState && (
          <div>
            {content}
          </div>
        )
      )
    }
  }

  readMoreInfo() {
    return (
      <small>
        {i18n('cookieConsent.more.part1')}
        &nbsp;
        <a
          target='new'
          className='underline'
          href={i18n('cookieConsent.more.link')}>
          {i18n('cookieConsent.more.part2')}
        </a>
      </small>
    )
  }

  moreInfo() {
    if (this.state.isCustomEmbeddedShop) {
      if (this.state.moreInfoExpanded) {
        return (
          <div>
            <h2>{i18n('cookieConsent.header')}</h2>
            <p>{i18n('cookieConsent.body')}</p>
          </div>
        )
      }
    } else {
      return (
        <div>
          <h1>{i18n('cookieConsent.header')}</h1>
          <p>{i18n('cookieConsent.body')}</p>
        </div>
      )
    }
  }

  render() {
    return (
      this.collapsableContainer(
        this.state.consentMissingOrExpired,
        <>
          <div id='cookie-consent-form' className='cookie-consent-form' ref={this.consentForm}>
            <div className='cookie-consent-form__outer-wrapper'>
              <div className='cookie-consent-form__inner-wrapper'>
                <div className='cookie-consent-form__info'>
                  {!this.state.isCustomEmbeddedShop && this.moreInfo()}
                  <div className='cookie-consent-form__more-info'>
                    <div>
                      <button
                        className='cookie-consent-form__expand'
                        onClick={this.toggleCookies}>
                        {i18n('cookieConsent.manage')}
                        &nbsp;&nbsp;
                        <i className='fa fa-chevron-down'
                           aria-hidden={true}
                           style={{transform: this.state.cookiesExpanded && 'rotate(180deg)'}}/>
                      </button>
                      {this.state.isCustomEmbeddedShop && (
                        <button
                          className='cookie-consent-form__expand'
                          onClick={this.toggleMoreInfo}>
                          {i18n('cookieConsent.moreInfo')}
                          &nbsp;&nbsp;
                          <i className='fa fa-chevron-down'
                             aria-hidden={true}
                             style={{transform: this.state.moreInfoExpanded && 'rotate(180deg)'}}/>
                        </button>
                      )}
                    </div>
                    {!this.state.isCustomEmbeddedShop && this.readMoreInfo()}
                  </div>
                  {this.state.isCustomEmbeddedShop && this.moreInfo()}
                </div>
                <div className={classNames('cookie-consent-form-collapse', {
                  'opened': this.state.cookiesExpanded,
                  'animated': !this.state.isCustomEmbeddedShop
                })}>
                  <div>
                    <div className='cookie-consent-form__consents'>
                      <ToggleSwitch
                        toggleLabel={i18n('cookieConsent.consent.functional')}
                        currentState={true}
                        disabled={true}/>
                      <ToggleSwitch
                        toggleLabel={i18n('cookieConsent.consent.analytical')}
                        currentState={this.state.consentAnalyticalCookies}
                        toggleTarget={CONSENT_ANALYTICAL}
                        handleToggle={this.toggleCookie}/>
                      <ToggleSwitch
                        toggleLabel={i18n('cookieConsent.consent.marketing')}
                        currentState={this.state.consentMarketingCookies}
                        toggleTarget={CONSENT_MARKETING}
                        handleToggle={this.toggleCookie}/>
                    </div>
                  </div>
                  <button
                    onClick={this.saveCurrentConsent}
                    className='cookie-consent-form__apply'>
                    {i18n('cookieConsent.apply')}
                  </button>
                </div>
              </div>
            </div>
            <button
              onClick={this.acceptAll}
              className='cookie-consent-form__accept'>
              {i18n(this.state.isCustomEmbeddedShop
                ? 'cookieConsent.accept.cookies'
                : 'cookieConsent.accept.all')}
            </button>
            {this.state.isCustomEmbeddedShop && this.readMoreInfo()}
          </div>
        </>
      )
    )
  }
}

CookieConsent.propTypes = {
  identifier: PropTypes.number, // Only relevant for embedded
  basketMode: PropTypes.bool // Only relevant for embedded
}

export default CookieConsent;
